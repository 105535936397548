import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', component: require(page+'home.vue').default },
      { path: '/nosotros', component: require(page+'about-us/index.vue').default, meta:{ title:"Nosotros" }},
      { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{ title:"Contacto" }},
      { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de privacidad"}},
      // { path: '/terminos-y-condiciones', component: require(page+'text-pages/terminos.vue').default, meta:{title:"Términos y condiciones"}},

      { path: '/blog', component: require(page+'blog/index.vue').default, meta:{title:"Blog"}},
      { path: '/blog/:id', component: require(page+'blog/detail.vue').default, meta:{title:"Blog"}},

      { path: '/productos', component: require(page+'products/index.vue').default, meta:{ title:"Productos" }},
      // { path: '/producto/:id', component: require(page+'products/detail.vue').default, meta:{ title:"Producto" }},
      { path: '/producto/1', component: require(page+'products/detail-1.vue').default, meta:{ title:"Garrafón Pet 20 litros" }},
      { path: '/producto/2', component: require(page+'products/detail-2.vue').default, meta:{ title:"Garrafón PVC 11 litros" }},
      { path: '/producto/3', component: require(page+'products/detail-3.vue').default, meta:{ title:"Bolsa con 5 kg de hielo purificado" }},
      { path: '/producto/4', component: require(page+'products/detail-4.vue').default, meta:{ title:"Botellas 1.5 litros" }},
      { path: '/producto/5', component: require(page+'products/detail-5.vue').default, meta:{ title:"Botellas 1 litro" }},
      { path: '/producto/6', component: require(page+'products/detail-6.vue').default, meta:{ title:"Botellas 500mL" }},
      { path: '/producto/7', component: require(page+'products/detail-7.vue').default, meta:{ title:"Botellas 250mL" }},

      { path: '/login', component: require(page+'user-access/login.vue').default, meta:{title:"Iniciar sesión"}},
      { path: '/recuperar-contrasena', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Recuperar contraseña"}},
      { path: '/registrarse', component: require(page+'user-access/register.vue').default, meta:{title:"Registrarse"}},

      { path: '/cart/:step?', component: require(page+'carrito/index.vue').default, meta:{title:"Mi carrito de compras"}},

      { path: '/usuario', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'Mi datos' },
  			children: [
  			{
  				path: '/',
  				component: require(page+'usuario-cuenta/mis-datos.vue').default,
  				meta:{ title: 'Mis datos' }
  			},
  			{
  				path: 'contrasena',
  				component: require(page+'usuario-cuenta/contrasena.vue').default,
  				meta:{ title: 'Cambiar contraseña' }
  			},
        {
            path: 'historial-compras',
            component: require(page+'usuario-cuenta/historial-compras.vue').default,
            meta:{ title: 'Mis pedidos' }
        },
        {
            path: 'pedido/:id',
            component: require(page+'usuario-cuenta/orderdetails.vue').default,
            meta:{ title: 'Mis pedidos' }
        },
  	  	]
  		},

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "Checa, Agua Purificada Baja en Sales", filter: (title)=>{ return title+" - Checa, Agua Purificada Baja en Sales"; } }
);

// export {routes};
export default MyRouter;
